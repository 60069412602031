// src/App.js
import React, {useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ResidentialRental from "./components/ResidentialRental";
import ProtectedRoute from "./components/ProtectedRoute";
import FilterPage from "./components/FilterPage";
import Footer from "./components/Footer";
import PaymentStatusPage from "./components/PaymentStatusPage";

const App = () => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/filter"
          element={<ProtectedRoute element={FilterPage} />}
        />
        <Route
          path="/residential-rental"
          element={<ProtectedRoute element={ResidentialRental} />}
        />
        <Route
          path="/payment-success"
          element={<ProtectedRoute element={PaymentStatusPage} />}
        />
        {/* Add more routes as needed */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;