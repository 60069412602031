// src/components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import "./Navbar.css";
import image from "../image/probrocker-logo.png";
import HelpButton from "./HelpButton"; // Adjust the path accordingly

const Navbar = () => {
  const isLoggedIn = Cookies.get("userId");
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const handleLogout = () => {
    Cookies.remove("userId"); // Remove the userId cookie to log out the user
    window.location.href = "/"; // Redirect to the home page
    window.location.reload(); // Reload the page to apply the logout state
  };

  return (
    // <nav>
    //   <Link to="/">Home</Link>
    //   {isLoggedIn ? (
    //     <>
    //       <Link to="/profile">Profile</Link>
    //       <Link to="/" onClick={handleLogout}>Logout</Link>
    //     </>
    //   ) : (
    //     <>
    //       <Link to="/login">Login</Link>
    //       <Link to="/signup">Signup</Link>
    //     </>
    //   )}
    // </nav>
    // <nav className="navbar">
    //   <div className="navbar-logo">
    //     <img src={image} alt="Pro Broker" />
    //   </div>

    //   <div className={`navbar-menu ${menuActive ? "active" : ""}`}>
    //     <a href="/">Home</a>
    //     {isLoggedIn ? (
    //       <>
    //         <Link to="/profile">Profile</Link>
    //         <Link to="/" onClick={handleLogout}>
    //           Logout
    //         </Link>
    //       </>
    //     ) : (
    //       <>
    //         <Link to="/login">Login</Link>
    //         <Link to="/signup">Signup</Link>
    //       </>
    //     )}
    //   </div>
    //   <div className="navbar-toggle" onClick={toggleMenu}>
    //     ☰
    //   </div>
    // </nav>

    <nav className="navbar z-20">
      <div className="navbar-logo">
        <a href="/">
          <img src={image} alt="Pro Broker" />
        </a>
      </div>

      <div className={`navbar-menu ${menuActive ? "active" : ""}`}>
        <Link to="/" className="hover:bg-[#f0f0f0] text-black px-3 py-1.5 rounded-lg">Home</Link>
        {isLoggedIn ? (
          <>
            {/* <Link to="/profile">Profile</Link> */}
            <button onClick={handleLogout} className="hover:bg-white bg-[#503691] border border-[#503691] text-white hover:text-[#503691] px-6 py-1.5 rounded-full">
              Logout
            </button>
          </>
        ) : (
          <>
            <Link to="/signup" className="bg-white font-bold hover:bg-[#503691] border border-[#503691] hover:text-white text-[#503691] px-6 py-1.5 rounded-full">
              Signup
            </Link>
            <Link to="/login" className="hover:bg-white font-bold bg-[#503691] border border-[#503691] text-white hover:text-[#503691] px-6 py-1.5 rounded-full">
              Login
            </Link>
          </>
        )}
      </div>
      <div className="navbar-toggle" onClick={toggleMenu}>
        ☰
      </div>
      <HelpButton />
    </nav>
  );
};

export default Navbar;