import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <img
            src="./image/Group 427319319.png"
            alt="Probroker"
            className="footer-logo"
          />
          <p className="tagline">
            #1 AI based platform for brokers in Ahmedabad & Gandhinagar
          </p>
          <div className="social-icons">
            <a href="#">
              <img src="./image/face book.png" alt="Facebook" />
            </a>
            <a href="#">
              <img src="./image/twitter.png" alt="Twitter" />
            </a>
            <a href="#">
              <img src="./image/instagram.png" alt="Instagram" />
            </a>
            <a href="#">
              <img src="./image/youtub.png" alt="YouTube" />
            </a>
          </div>
        </div>
        <div className="footer-middle">
          <h3>Contact</h3>
          <div className="icon-set">
            <FontAwesomeIcon className="icons" icon={faMapMarkerAlt} />
            <p className="middle-text">
              1st Floor, AIC GSUEC Atal Kalam Research Park Inside Gujarat
              University, Navrangpura 380009
            </p>
          </div>
          <div className="icon-set">
            <FontAwesomeIcon className="icons1" icon={faPhoneAlt} />
            <p className="middle-text">9316066832 , 8141817353</p>
          </div>
          <div className="icon-set">
            <FontAwesomeIcon className="icons1" icon={faClock} />
            <p className="middle-text"> Working Hours: 12pm To 7pm</p>
          </div>
        </div>
        <div className="footer-right">
          <p>
            {/* <ul> */}
            Now available in Ahmedabad & Gandhinagar
            {/* </ul> */}
          </p>
          {/* <div className="footer-image">
            <img src="./image/Apartment (traced).png" alt="Footer Image" />
          </div> */}
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-wave">
          <p>© 2023 All rights reserved - Vocal Connect Pvt Ltd</p>
          <img src="./image/Frame 28.png" alt="Footer Image" />
          {/* <p>© 2023 All rights reserved - Vocal Connect Pvt Ltd</p> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
